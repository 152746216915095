.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Record-raw-key {
    color: #9b2b68;
}

.Record-raw-value {
    color: #2b1491;
}

.ReactTags__tags {
    position: relative;
}


/* Styles for the input */

.ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
}


/* Styles for selected tags */

.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #63bcfd;
    color: white;
    font-size: 16px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
}

.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}


/* Styles for suggestions */

.ReactTags__suggestions {
    position: absolute;
}

.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
}

.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}

.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}

.ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
}

.fab {
    margin: 0px;
    top: auto;
    left: 3px;
    bottom: 3px;
    right: auto;
    position: fixed !important;
}

.fab_right {
    margin: 0px;
    top: auto;
    right: 3px;
    bottom: 3px;
    left: auto;
    position: fixed !important;
    background-color: #80e040;
    opacity: 0.85;
    border: 1px solid black;
}